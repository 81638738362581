<template>
  <div>
    <table-spinner :loading="pageLoading"></table-spinner>
    <div v-if="!pageLoading">
      <b-card no-body class="mb-0 p-1">
        <template #header></template>
        <profile-form v-if="user" :user="user" @save="onSaveUser" />
        <br />
        <subscription-information :user="user" :hide-upgrade-services="true" />
        <div v-if="user" class="p-2">
          <b-button
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            variant="outline-primary"
            @click="onLoginAsUser"
          >
            Log in as
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            @click="onEmailVerify"
            v-if="!user.is_email_verified"
            >Email verify
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            @click="onSendPasswordReset"
          >
            Send password reset email
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            v-if="authUser.id !== user.id"
            @click="onBan(!user.is_banned)"
            :disabled="loading"
          >
            {{ user.is_banned ? "Remove ban" : "Ban" }}
            <b-spinner
              v-if="loading"
              small
              type="grow"
              class="m-auto"
            ></b-spinner>
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            v-if="authUser.id !== user.id"
            @click="onInactivate(!user.is_inactivated)"
            :disabled="loading"
          >
            {{ user.is_inactivated ? "Activate" : "Inactivate" }}
            <b-spinner
              v-if="loading"
              small
              type="grow"
              class="m-auto"
            ></b-spinner>
          </b-button>
        </div>
        <user-service-list :userServices="userServices"></user-service-list>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BSpinner } from "bootstrap-vue";
import { mapActions } from "vuex";
import { fetchUser } from "@/services/api/admin";
import ProfileForm from "@/layouts/components/user/ProfileForm.vue";
import { apiErrorHandler } from "@/services/utils/util";
import SubscriptionInformation from "@/layouts/components/user/SubscriptionInformation.vue";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import UserServiceList from "@/layouts/components/user/UserServiceList.vue";
import { getUserServicesApi } from "@/services/api/auth";
import { onUserBanToggle, onUserInactivateToggle } from "@/services/utils/util";
import Vue from "vue";

export default {
  components: {
    BCard,
    ProfileForm,
    UserServiceList,
    BButton,
    BSpinner,
    TableSpinner,
    SubscriptionInformation,
  },
  data() {
    return {
      user: null,
      id: this.$route.params.id,
      loading: false,
      pageLoading: false,
      userServices: null,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
  },
  mounted() {
    this.onFetchUserData();
    this.getUserServices();
  },
  created() {
    this.$watch(
      () => this.$route.params.id,
      (id) => {
        this.id = id;
        this.onFetchUserData();
      }
    );
  },
  methods: {
    ...mapActions({
      updateUsers: "adminUser/updateUsers",
      banUserToggle: "adminUser/banUserToggle",
      inactivateUserToggle: "adminUser/inactivateUserToggle",
      verifyUserEmail: "adminUser/verifyUserEmail",
      forgetPassword: "adminUser/forgetPassword",
      loginAs: "auth/loginAs",
    }),
    async onFetchUserData() {
      this.pageLoading = true;
      try {
        const user = await fetchUser(this.id);
        this.user = user;
      } catch (e) {
        apiErrorHandler(e);
      } finally {
        this.pageLoading = false;
      }
    },
    async onBan(checked) {
      const user = this.user;
      try {
        await onUserBanToggle(checked, user, this.banUserToggle);
      } finally {
        await this.onFetchUserData();
      }
    },
    async onInactivate(checked) {
      const user = this.user;
      try {
        await onUserInactivateToggle(checked, user, this.inactivateUserToggle);
      } finally {
        await this.onFetchUserData();
      }
    },
    async onSaveUser(user) {
      try {
        await this.updateUsers(user);
        if (user.epg_timezone !== this.user.epg_timezone) {
          Vue.$toast.info(
            "Regenerating the EPG XML link may take several minutes after changing the time zone."
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        await this.onFetchUserData();
      }
    },
    async onLoginAsUser() {
      await this.loginAs(this.user);
    },
    async onEmailVerify() {
      await this.verifyUserEmail(this.user);
    },
    async onSendPasswordReset() {
      await this.forgetPassword(this.user);
    },
    async getUserServices() {
      try {
        const services = await getUserServicesApi(this.id);
        this.userServices = services;
      } catch (e) {
        apiErrorHandler(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.profile-form {
  flex: 0 0 100%;
  max-width: 100%;
}
.action-buttons {
  margin: 10px;
}
@media only screen and (max-width: 768px) {
  .action-buttons {
    margin: 10px auto 0 auto;
  }
}
</style>
