<template>
  <div>
    <h3 class="mt-2">User service history</h3>
    <ul v-if="userServices.length" class="mt-1">
      <li v-for="userService in userServices" :key="userService.id">
        <span class="font-weight-bolder"
          >Channel: {{ userService.display_name }}</span
        >
        <ul>
          <li>Begin at: {{ userService.begin_at }}</li>
          <li>Finish at: {{ userService.finish_at }}</li>
          <li>Quantity: {{ userService.quantity }}</li>
        </ul>
      </li>
    </ul>
    <div v-else>No history found for this user</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    userServices: {
      type: Array,
      required: false,
    },
  },
};
</script>
