<template>
  <b-spinner
    class="ml-auto mr-auto mb-3"
    variant="success"
    type="grow"
    label="Spinning"
    style="width: 4rem; height: 4rem"
    v-if="loading"
  ></b-spinner>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
export default {
  components: {
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
